<template>
  <div class="relative">
    <navBar>
      <div class="pages">
        <nav>
          <el-tabs @tab-click="handleClick" class="tabs">
            <el-tab-pane
              v-for="(tabsItem, index) in tabs"
              :key="index + 'index'"
              :label="tabsItem.title"
            >
              <div
                v-for="(item, index) in list"
                :key="index"
                class="mt20"
                @click="toDetail(item)"
              >
                <work-order :list="item"></work-order>
              </div>
              <div class="flex flex-right">
                <el-pagination
                  @current-change="handleCurrentChange"
                  :page-size="10"
                  layout="prev, pager, next, jumper"
                  :total="total"
                ></el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>
        </nav>
      </div>
    </navBar>
    <div
      class="addbtn flex flex-center align-center"
      @click="hanleAddWorkOrder"
    >
      <img src="@/assets/workOrder/add.png" alt="" />
    </div>
    <AddWorkOrder
      :show="addDWorkOrderialog"
      @submit="submit"
      @handleClose="addDWorkOrderialog = false"
    ></AddWorkOrder>
  </div>
</template>

<script>
import { Loading } from 'element-ui'
import navBar from '@/components/navBar/index.vue'
import WorkOrder from '@/components/workOrder.vue'
import AddWorkOrder from '../../../components/addWorkOrder.vue'
export default {
  components: {
    navBar,
    WorkOrder,
    AddWorkOrder
  },
  data() {
    return {
      addDWorkOrderialog: false,
      total: 0,
      detail: '',
      loading: '',
      query: {
        pageNo: 0,
        pageSize: 10,
        status: ''
      },
      tabs: [
        { title: 'Todos' },
        { title: 'PorTratar' },
        { title: 'Procesado' },
        { title: 'Completado' }
      ],
      statusTpye: {
        Todos: '',
        PorTratar: 'UNTREATED',
        Procesado: 'PROCESSING',
        Completado: 'COMPLETED'
      },
      list: ''
    }
  },
  created() {
    this.getList()
  },
  methods: {
    toDetail(val) {
      console.log('工单详情', val)
      this.$router.push({
        path: '/detail',
        name: 'workOrder-detail',
        params: { detail: val }
      })
    },
    getList() {
      this.loading = Loading.service(true)
      this.$axios.post('/workOrder/list', this.query).then((res) => {
        this.total = Number(res.data.result.total)
        this.list = res.data.result.rows
        console.log('列表', this.list)
        this.loading.close()
      })
    },
    handleClick(tabs) {
      let data = this.statusTpye[tabs.label]
      this.query.status = data
      this.getList()
      console.log('data', data)
      console.log(tabs)
    },
    handleCurrentChange(val) {
      this.query.pageNo = val
      this.getList()
    },
    submit() {
      setTimeout(() => {
        this.addDWorkOrderialog = false
        this.getWorkOrderList()
      }, 500)
    },
    hanleAddWorkOrder() {
      this.addDWorkOrderialog = true
      console.log('点击了')
    }
  }
}
</script>

<style lang="scss" scoped>
.pages {
  margin: auto;
  width: 1200px;
}
.tabs {
  border: none !important;
}
</style>
<style lang="scss">
.tabs .el-tabs__item.is-active {
  color: #45b035 !important;
}
.tabs .el-tabs__active-bar {
  background-color: #45b035 !important;
}
.addbtn {
  position: absolute;
  right: 150px;
  bottom: 330px;
  border: solid 1px #e7e7e7;
  height: 68px;
  width: 68px;
  img {
    width: 24px;
    height: 24px;
  }
}
</style>
